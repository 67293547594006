import React from 'react';
import "../src/Home.css";
import douwa1 from "../src/images/image1.jpg";
import douwa2 from "../src/images/image2.jpg";
import { Link } from 'react-router-dom';
import { initLocalStorage } from './services/apiConnect';

//ホーム画面
function Home() {
    // localStorageの初期化
    initLocalStorage()
    return (
        <>
        <div className="App">
          <label>
              <input type="radio" name="storybook" value="storybook1"></input>
              <Link to="/AppDescription"><img src={douwa1} alt="童話1" className='image-hover' ></img></Link>
              <p>童話の場面を生成</p>
          </label>
          <label>
              <input type="radio" name="storybook" value="storybook2"></input>
              <Link to="/AppDescription_idea2"><img src={douwa2} alt="童話2" className='image-hover' ></img></Link>
              <p>お題に沿った画像を生成</p>
          </label>
        </div>
        </>
    )
  }
  export default Home;