import React from 'react';
import CommonHeader from './CommonHeader';
import "../src/AppDescription.css";
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function AppDescription_idea2() {
  
    return (
    <div>
      <CommonHeader />      
      <div className='text-itme-desc'>
        <h2>お題に沿った生成についての説明</h2>
        <div className='text-explanation'>
            <p>
              7つの異なるお題の画像を選択しの中から1つ童話を選択します。<br />
              選択したお題の画像と同じような画像になるように命令文を指定していただき生成します。<br />
              編集することも可能で、人物の服の色や表情、画像の画調を変更することができます。<br />
              お題を指定せずに自由に命令文を作成していただき生成していただくことができます。
            </p>
          </div>
        <div className='text-h2'>
          <h2>注意事項</h2>
            <div className='text-Notes'>
              <p>
                ・画面のリロードをしないでください。<br />
                ・完了ボタンを押した後に、印刷をすることはできません。<br />
                ・必ずしも命令文通りに画像が生成されるとは限りません。<br />
                ・命令文によっては、生成自体ができずエラーが発生する可能性があります。<br />
                ・再生成時、テキストか画風のどちらかが未記入、未選択の場合は生成できません。
              </p>
            </div>
          </div>
        </div>
      <Link to="/ImageSelection"><Button variant="pink" type="button" className="btn1">確認</Button></Link>
    </div>
  )
}
export default AppDescription_idea2;
 