import React, { useState } from "react";
import { Row,Col,Card,Button } from 'react-bootstrap';
import "./ImageEdit.css"
import { Link } from "react-router-dom";
import Modal from "./components/Modal";
import { fetchTaleData, saveToHistory } from "./services/apiConnect";
import { initLocalStorage, clearRegenerateHistory } from "./services/apiConnect";
import { printImage } from './services/PrintImage';

function ImageEdit_idea1({props}) {

    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    //console.log(props);

    const handleRadioChange = (e) => {
        props.setSelectedOption(e.target.value);
    }

    // 「画像再選択」で再生成時使っていた値を空にする
    const resetValue = () => {
        // props.setTextValue('');
        props.setSelectedOption('');
        clearRegenerateHistory();
    }

    // 再生成のリクエスト呼出し
    const handleReGenerate = async (user_input, seed, style, history) => {
        props.setIsLoading(true);
        const data = await fetchTaleData(user_input, seed, style, history); 
        // console.log(data);
        // 再生成では1枚しか生成されないため直接配列番号を指定して格納, seed値を引き継ぐ
        props.updateSelectedImage(data.generate_images[0].base64, seed);
        // console.log("===save To History called===");
        // console.log(user_input, data.generate_prompt);
        saveToHistory(user_input, data.generate_prompt); // historyに保存
        setTimeout(() => {
          props.setIsLoading(false);
          props.setIsGenerate(true);
        }, 500);
      }

    const handleGenerateAgain = async () => {
        console.log("handleGenerateAgain called");
        console.log("再生成ボタンがクリックされました");
        // 両方nullの場合、アラートを出す
        if (!props.selectedOption) {
            alert("画風は必ず変更してください");
            return;
        }
        // 画像再表示のために props.updateIsEdit に保持している値を渡す
        // if (props && typeof props.updateIsEdit === 'function') {
        //     console.log(props.isLoading);
        //     props.updateIsEdit();
            // console.log(textValue);
            // console.log(selectedOption);
            // console.log(props.seed);
            /*
            historyの型がlocalStorageに保存した際にStringになってしまうので
            それを適切な型へ変換する必要がある
            */
        try {
            const historyOfString = localStorage.getItem('history');
            const historyObject = JSON.parse(historyOfString);
            // 初回生成の命令をuser_inputに追加する(精度向上のため)
            let user_input = props.gptInput;
            await handleReGenerate(user_input, props.seed, props.selectedOption, historyObject);
        //ロード処理中にエラーが発生した時の実装
        } catch (error) {
            console.error('APIリクエストエラー: ', error);
            alert('画像が生成できませんでした。もう一度試してください。');
            props.setIsLoading(false);
        }
    }

    const handlePrintAndHide = () => {
        console.log('run handlePrintAndHide');
        printImage(`data:image/png;base64,${props.selectedImage}`);
        handleHide();
    }

    const handleHide = () => {
    setShow(false);
    };

    // モーダル表示
    const handleModalShow = () => {
        setModalShow(true);
      }
    
    // モーダル非表示
    const handleModalHide = () => { 
    setModalShow(false); 
    }
    
    return (
        <div className="Edit">
            <div className="Edit-form">
                <p style={{"fontSize":"27px", "marginLeft":"60px", "padding-top":"0px"}}>◆<u>画風を変更して再生成することができます！</u></p>
                {/* <div class="Edit-form-control">
                    <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="テキスト" rows="3" value={props.textValue} onChange={(e) => props.setTextValue(e.target.value)} ></textarea>
                </div> */}

                <div className='Edit-form-check-1'>
                <Row>
                    {/* <Col className='Edit-select'><label><input type="radio" name="scene" value="" onChange={handleRadioChange} checked={props.selectedOption === ""}/>選択しない</label><br /></Col> */}
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="anime" onChange={handleRadioChange} checked={props.selectedOption === "anime"}/>アニメ風</label><br /></Col>
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="custom-Gogh" onChange={handleRadioChange} checked={props.selectedOption === "custom-Gogh"}/>ゴッホ風</label><br /></Col>
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="custom-picasso" onChange={handleRadioChange} checked={props.selectedOption === "custom-picasso"}/>ピカソ風</label><br /></Col>

                </Row>
                <Row>
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="digital-art" onChange={handleRadioChange} checked={props.selectedOption === "digital-art"}/>デジタルアート風</label><br /></Col>
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="pixel-art" onChange={handleRadioChange} checked={props.selectedOption === "pixel-art"}/>ドット絵風</label><br /></Col>
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="custom-watercolor-paintin" onChange={handleRadioChange} checked={props.selectedOption === "custom-watercolor-paintin"}/>水彩画風</label><br /></Col>
                </Row>
                <Row>
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="origami" onChange={handleRadioChange} checked={props.selectedOption === "origami"}/>折り紙風</label><br /></Col>
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="modeling-compound" onChange={handleRadioChange} checked={props.selectedOption === "modeling-compound"}/>粘土風</label><br /></Col>
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="custom-no-color-japanese-black-and-white-manga" onChange={handleRadioChange} checked={props.selectedOption === "custom-no-color-japanese-black-and-white-manga"}/>マンガ風</label><br /></Col>
                </Row>
                <Row>
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="3d-model" onChange={handleRadioChange} checked={props.selectedOption === "3d-model"}/>3D風</label><br /></Col>
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="comic-book" onChange={handleRadioChange} checked={props.selectedOption === "comic-book"}/>海外マンガ風</label><br /></Col>
                    <Col className='Edit-select'><label><input type="radio" name="scene" value="custom-stained-glass" onChange={handleRadioChange} checked={props.selectedOption === "custom-stained-glass"}/>ステンドグラス風</label><br /></Col>
                </Row>
                </div>

                <div className="Edit-form-Button">
                    <Row>
                            <Col className="Edit"><Button variant="primary" type="button" className="Edit-button1" onClick={() =>{resetValue(); props.setIsEdit(false)}}>画像を選びなおす</Button></Col>
                            <Col className="Edit"><Button variant="primary" type="button" className="Edit-button1" onClick={() => handleGenerateAgain()} >再生成</Button></Col>
                        </Row>
                        <Row>
                            <Col className="Edit"><Link to="/"><button variant="pink" type="button" className="Edit-button2" onClick={initLocalStorage} as="a">完了</button></Link></Col>
                            <Col className="Edit"></Col>
                        </Row>
                    </div>
                </div>

            <div className="Edit_ImageButton">
                <div className="Edit_Image">
                    <Card className="Edit_Image-Card">
                        <Card.Body>
                            <Card.Text>
                                <img src={`data:image/png;base64,${props.selectedImage}`}
                                 alt="再生成画像"
                                 onClick={handleModalShow} 
                                />
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="Edit-Button">
                    {/* <button className="Edit-button3" as="a" variant="primary" >保存</button>
                    <button className="Edit-button3" as="a" variant="primary" >印刷</button> */}
                    <Row>
                        <Col className="Edit"><Button ariant="primary" type="button" className="Edit-button3" as="a" variant="primary" onClick={handlePrintAndHide}>印刷</Button></Col>
                    </Row>
                </div>
                <div className='.modal-5-content'>
                    <Modal 
                    show={modalShow}
                    title="選択した画像"
                    centered
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    size="lg"
                    body={
                        <div className='show-image-Gallary'>
                        <img src={`data:image/png;base64,${props.selectedImage}`} alt="生成画像"></img>
                        </div>
                    }
                    onHide={handleModalHide}>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

export default ImageEdit_idea1;