import { Card,Image } from 'react-bootstrap';
import React, {useState} from "react";
import Theme01 from "../src/images/theme01.png";
import Theme02 from "../src/images/theme02.png";
import Theme03 from "../src/images/theme03.png";
import Theme04 from "../src/images/theme04.png";
import Theme05 from "../src/images/theme05.png";
import Theme06 from "../src/images/theme06.png";
import Theme07 from "../src/images/theme07.png";
import "../src/GenerationResults_idea2.css";
import { Link,useLocation } from 'react-router-dom';
import Modal from './components/Modal';
import { printImage } from './services/PrintImage';
import { initLocalStorage } from './services/apiConnect';

function GenerationResults_idea2({ props }) {

  const [show, setShow] = useState(false);

  const handleShow = (base64, seed) => {
    setShow(true);
    props.updateSelectedImage(base64);
    props.updateSelectedImageSeed(seed);
    console.log(seed);
  }

  const handlePrintAndHide = () => {
    console.log('run handlePrintAndHide');
    printImage(`data:image/png;base64,${props.selectedImage}`);
    handleHide();
  };

  const handleHide = () => {
    setShow(false);
  }

  const location = useLocation();
  const queryPrams = new URLSearchParams(location.search);
  const params = queryPrams.get('dowa');
  let data = [];

  switch(params) {
    case "Theme1":
      data = Theme01
      break;
    case "Theme2":
      data = Theme02
      break;
    case "Theme3":
      data = Theme03
      break;
    case "Theme4":
      data = Theme04
      break;
    case "Theme5":
      data = Theme05
      break;
    case "Theme6":
      data = Theme06
      break;
    default:
      data = Theme07
      break;
  }

  return (
    <div>
      <div className="GeneratedResult_idea2">
    
      {/* <span class="border border-3">生成画面で入力した命令</span> */}

        <div className='GeneratedResult_idea2_main'>
          <div className='idea2-Image'>
            <div class="Result_idea2_text" >
              <p>
                <strong>主題: </strong>{props.inputValues.Subject || "No subject"}<br />
                <strong>様子: </strong>{props.inputValues.Action || "No action"}<br />
                <strong>場所: </strong>{props.inputValues.Location || "No location"}<br />
                <strong>その他: </strong>{props.inputValues.AdditionalDetails || "未入力"}

                {/* { Object.values(props.inputValues).map((value) => (
                  value
                )) } */}
              </p>
            </div>
          
            <Card className="Image-Card">
              <Card.Body>
                <Card.Text>
                  <div className='ImageSet-1'>
                  { props.images.map((image, index) => (
                    <div className={`Image-${index + 1}`} key={index}>
                      <img onClick={() => handleShow(image.base64, image.seed)} src={`data:image/png;base64,${image.base64}`} alt="生成画像" />
                    </div>
                  ))}
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className='.modal-5-content'>
        <Modal 
          centered
          show={show}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          title="選択した画像"
          body={
            <div className='show-image'>
              <img src={`data:image/png;base64,${props.selectedImage}`} alt="生成画像"></img>
            </div>
          }
          footer={
            <>
            <button type="button" className="btn-3" variant="secondary" onClick={() => props.updateIsEdit()}>編集</button>
            <button type="button" className="btn-3" variant="secondary" onClick={handlePrintAndHide}>印刷</button>
            </>
          }
          onHide={handleHide}>
        </Modal>
      </div>

          <div className='Image-Button'>
            <div className='Image5'>
              
            <Card className="Image-Button-Card">
              <Card.Body>
                <Card.Text>
                  <Image src={data} />
                </Card.Text>
              </Card.Body>
            </Card>
            </div>

            <div className="button-container">
             <Link to="/ImageSelection"><button className="return-button" onClick={initLocalStorage} as="a" variant="primary">お題を選びなおす</button></Link>
             <Link to="/"><button className="result-idea2-button" onClick={initLocalStorage} as="a" variant="primary" >完了</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerationResults_idea2;
