import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";


function Loading() {
    const [spinner2, setSpinner2] = useState(false);
    const [spinner3, setSpinner3] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        setTimeout(() => {
            setSpinner2(true);
        }, 100);

        setTimeout(() => {
            setSpinner3(true);
        }, 300)
    }, [])

    return (
        <div style={{ display: "flex", justifyContent: "center", position: "relative", alignItems: "center"}}>
            <p style={{fontSize: "35px", textAlign: "left", marginLeft: "100px", paddingTop: "10px", fontWeight: "bold", fontFamily: "Noto Serif"}}>画像を生成しています</p>
            <Spinner animation="grow" size="sm" />
            { spinner2 ? <Spinner animation="grow" size="sm" /> : <></> }
            { spinner3 ? <Spinner animation="grow" size="sm" /> : <></> }
        </div>
    )
}

export default Loading;
