import React, { useState } from 'react';
import CommonHeader from './CommonHeader';
import { useLocation } from 'react-router-dom';
import "../src/Generateidea1.css";
import jsonData from "./data/scene.json";
import { Button } from 'react-bootstrap';
import Loading from './components/Loading';
import { fetchTaleData } from './services/apiConnect';
import GenerationResults_idea1 from './GenerationResults_idea1';
import { saveToHistory } from './services/apiConnect';
import ImageEdit_idea1 from './ImageEdit_idea1';

//画像生成画面

function Generateidea1() {
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState(null);
  const [gptInput, setGptInput] = useState(null);
  const [seed, setSeed] = useState(0);
  const [images, setImages] = useState([]);
  const [isGenerate, setIsGenerate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // const [textValue, setTextValue] = useState("");  // テキストエリアの値を保持
  const [selectedOption, setSelectedOption] = useState("");  // ラジオボタンの選択を保持
  const [fairyTale, setFairytale] = useState("");

  const location = useLocation();
  const queryPrams = new URLSearchParams(location.search);
  const params = queryPrams.get('dowa');
  let data = {};

  switch(params) {
    case "シンデレラ":
      data = jsonData.シンデレラ
      break;
    case "美女と野獣":
      data = jsonData.美女と野獣
      break;
    case "ヘンゼルとグレーテル":
      data = jsonData.ヘンゼルとグレーテル
      break;
    case "不思議な国のアリス":
      data = jsonData.不思議な国のアリス
      break;
    case "白雪姫":
      data = jsonData.白雪姫
      break;
    case "ラプンツェル":
      data = jsonData.ラプンツェル
      break;
    case "ジャックと豆の木":
      data = jsonData.ジャックと豆の木
      break;
    default:
      data = jsonData.ピーターパン
      break;
  }

  // 画面に表示する場面一覧
  const showScenesData = Object.keys(data);
  // 内部で処理する場面について文章一覧
  const inputGPTScenesData = Object.values(data);
  const [selected, setSelected] = useState(() => Array(showScenesData.length).fill(false));
  // const [selected, setSelected] = useState(() => Array(data.length).fill(false));

  const handleButtonClick = (index) => {
    setSelected((prevSelected) => prevSelected.map((_, i) => i === index - 1));
    setInput(showScenesData[index]);
    setGptInput(inputGPTScenesData[index]);
    console.log('index: ', index);
    console.log('画面に表示されている場面: ', showScenesData[index]);
    console.log('GPTに渡す場面: ', inputGPTScenesData[index]);
    setFairytale(showScenesData[0]);
  }

  const selectFairytale = showScenesData[0];

  const handleGenerate = async (user_input, seed=0) => {
    console.log("userInput: ", user_input);
    if (selected.some((isSelected) => isSelected)) {
      try {
        setIsLoading(true);
        const data = await fetchTaleData(user_input, seed); 
        setImages(data.generate_images.map(item => (
          {
            "base64": item.base64,
            "seed": item.seed
          }
        ))); // 画像データをマッピングして保持
        // console.log("===save To History called===")
        // 選択した場面を解除する
        setSelected((prevSelected) => prevSelected.map(() => false));
        saveToHistory(user_input, data.generate_prompt); // historyに保存
        setTimeout(() => {
          setIsLoading(false);
          setIsGenerate(true);
        }, 500);
      } catch (error) {
        console.error('APIリクエストエラー: ', error);
        alert('生成の際にエラーが発生しました。もう一度お試しください。');
        setIsLoading(false);
      }
    } else {
      // ラジオボタンが未選択の場合のエラーハンドリング
      alert('場面を選択してください');
    }
  }

  const updateIsEdit = () => {
    setIsEdit(true);
  }

  const updateSelectedImage = (base64, seed) => {
    setSelectedImage(base64);
    setSeed(seed);
  }

  // const updateSelectedImageSeed = (seed)=> {
  //   setSeed(seed);
  // }

  if (isLoading) {
    return <Common><Loading /></Common>;
  }

  // 生成結果画面へ遷移 (isGenerate=True)
  if (!isLoading && isGenerate && !isEdit) {
    return <Common><GenerationResults_idea1 props={{images, setImages, isGenerate, setIsGenerate, handleGenerate, updateIsEdit, selectedImage, updateSelectedImage, input, fairyTale}} /></Common>
  }

  // 編集画面へ遷移 (isEdit=True)
  if (!isLoading && isGenerate && isEdit) {
    return <Common><ImageEdit_idea1 props={{setIsLoading, setIsGenerate, selectedImage, seed, updateIsEdit, updateSelectedImage, setIsEdit, setSelectedOption, selectedOption, gptInput}} /></Common>
  }

  return (
    <Common>
      <p>選択された童話：{selectFairytale}</p>
      <h2>場面選択 </h2>
        { showScenesData.slice(1).map((scene, index) => (
          <div className='form-check' key={index}>
            <Button variant="outline-danger" active={selected[index]} onClick={() => handleButtonClick(index + 1)}>{scene}</Button>
          </div>
        )) }
      <div className='text-area'>
        <h2>注意事項</h2>
        <p>
          ・場面は1場面のみ選択可能です。複数選択できません。<br />
          ・未選択の場合は生成できません。<br />
          ・命令文によっては、生成自体ができずエラーが発生する可能性があります。<br />
        </p>
      </div>
      <div>
        <Button variant="pink" type="button" className="btn-1" onClick={() => handleGenerate(gptInput)}>作成</Button>
      </div>
    </Common>
  );
}

function Common({ children }) {
  return (
    <div className='scene'>
      <CommonHeader />
      {children}
    </div>
  )
}

export default Generateidea1;
