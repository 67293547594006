import React, { useEffect, useState } from 'react';
import  "../src/CommonHeader.css";
import { useLocation, Link } from 'react-router-dom';
import { initLocalStorage } from './services/apiConnect';

//Header固定
function CommonHeader() {
  const [visabled, setVisabled] = useState(false)

  let location = useLocation();
  React.useEffect(() => {
    console.log (location.pathname)
  }, [location]);
  
    return (
      <div>
        <header className='headerGallary' onClick={initLocalStorage}>
        <h1 className="headline">
        {/* <a>PSC CREATOR</a> */}
          <Link to="/"><a>PSC CREATOR</a></Link>
        </h1>
        { location.pathname == "/" ? (
          <div className='home-Gallary'>
            <Link to="/Gallary"><a>履歴/ギャラリー</a></Link>
          </div>
        ) : <></> }
        </header>
      </div>
    )
}
export default CommonHeader;