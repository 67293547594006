import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from "../App";
import AppDescription from "../AppDescription";
import AppDescription_idea2 from "../AppDescription_idea2";
import CommonHeader from "../CommonHeader";
import TaleSelection from "../TaleSelection";
import Generateidea1 from "../Generateidea1";
import Home from "../Home";
import GenerationResults_idea2 from "../GenerationResults_idea2";
import Generation_idea2 from '../Generation_idea2';
import Gallary from '../Gallary';
import ImageSelection from '../ImageSelection';
import ImageEdit from '../ImageEdit';
import ImageEdit_idea1 from '../ImageEdit_idea1';
import Generation_idea2_free from '../Generation_idea2_free';
import GenerationResults_idea2_free from '../GenerationResults_idea2_free';


const AppRoutes = () => {
    return (
        <BrowserRouter>   
            <Routes path="/" element={<CommonHeader />}>
                <Route index={true} element={<App />} />    
                <Route path="AppDescription" element={<AppDescription />} />
                <Route path="AppDescription_idea2" element={<AppDescription_idea2 />} />
                <Route path="TaleSelection" element={<TaleSelection />} />
                <Route path="Generateidea1" element={<Generateidea1 />} />
                <Route path="Home" element={<Home />} />
                <Route path="Generation_idea2" element={<Generation_idea2 />} />
                <Route path="GenerationResults_idea2" element={<GenerationResults_idea2 />} />
                <Route path="Gallary" element={<Gallary />} />
                <Route path="ImageSelection" element={<ImageSelection />} />
                <Route path="ImageEdit" element={<ImageEdit />} />
                <Route path="ImageEdit_idea1" element={<ImageEdit_idea1 />} />
                <Route path="Generation_idea2_free" element={<Generation_idea2_free />} />
                <Route path="GenerationResults_idea2_free" element={<GenerationResults_idea2_free />} />
            </Routes>
        </BrowserRouter>
    )
};
export default AppRoutes;