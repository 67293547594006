import React from 'react';
import { Card,Col,Row,Container } from 'react-bootstrap';
import CommonHeader from './CommonHeader';
import Theme01 from "../src/images/theme01.png";
import Theme02 from "../src/images/theme02.png";
import Theme03 from "../src/images/theme03.png";
import Theme04 from "../src/images/theme04.png";
import Theme05 from "../src/images/theme05.png";
import Theme06 from "../src/images/theme06.png";
import Theme07 from "../src/images/theme07.png";
import { Link } from 'react-router-dom';
import "../src/ImageSelection.css"

//童話選択画面
function ImageSelection() {
  return (
    <div className="parentContainer">
      <CommonHeader />
      <p className='selectdouwa'>お題画像選択</p>
      {/* 画像生成のコンテンツを追加 */}
        <Container>
          <Row>
            <Col className='select-col'><Link to="/Generation_idea2?dowa=Theme1"><img src={Theme01} alt="Theme1" className='select-image' ></img></Link></Col>
            <Col className='select-col'><Link to="/Generation_idea2?dowa=Theme2"><img src={Theme02} alt="Theme2"className='select-image' ></img></Link></Col>
            <Col className='select-col'><Link to="/Generation_idea2?dowa=Theme3"><img src={Theme03} alt="Theme3"className='select-image' ></img></Link></Col>
            <Col className='select-col'><Link to="/Generation_idea2?dowa=Theme4"><img src={Theme04} alt="Theme4"className='select-image' ></img></Link></Col>
          </Row>
          <Row>
            <Col className='select-col'><Link to="/Generation_idea2?dowa=Theme5"><img src={Theme05} alt="Theme5"className='select-image' ></img></Link></Col>
            <Col className='select-col'><Link to="/Generation_idea2?dowa=Theme6"><img src={Theme06} alt="Theme6"className='select-image' ></img></Link></Col>
            <Col className='select-col'><Link to="/Generation_idea2?dowa=Theme7"><img src={Theme07} alt="Theme7"className='select-image' ></img></Link></Col>
            <Col className='select-col'><Link to="/Generation_idea2_free">
              <Card className='select-card'>
                <Card.Body> 
                  <Card.Text className='select-card'>
                    <p style={{"lineHeight": "1.8", "fontSize":"25px"}}>
                    <b>自由に画像を作る</b><br />
                    <br />
                    お題の画像を選択せず
                    画像を作成できます！
                    体験したい方はこちら！
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card></Link>
            </Col>
          </Row>
        </Container>
      {/* <div>
        <Link to="/Generateidea1"><button type="button" className="btn-1">次へ</button></Link>
      </div> */}
    </div>
  );
}

export default ImageSelection;

