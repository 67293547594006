import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../src/GenerationResults_idea1.css";
import Modal from './components/Modal';
import { printImage } from './services/PrintImage';
import { Button } from 'react-bootstrap';
import { initLocalStorage } from './services/apiConnect';


//画像生成画面
function GenerationResults_idea1 ({ props }) {
  const [show, setShow] = useState(false);

  const handleShow = (base64, seed) => {
    setShow(true);
    props.updateSelectedImage(base64, seed);
    console.log(seed);
  }

  const handlePrintAndHide = () => {
    console.log('run handlePrintAndHide');
    printImage(`data:image/png;base64,${props.selectedImage}`);
    handleHide();
  };

  const handleHide = () => {
    setShow(false);
  }

  const returnChooseScene = () => {
    console.log(props.isGenerate);
    props.setIsGenerate(false);
    initLocalStorage();
  }

  return (
  <>
    <div className='fome-scene-image'>
      <p>選択された童話：{props.fairyTale}<br />
        選択された場面：{props.input}
      </p>
      <div className='image'>
        <div className='image-set1'>
          { props.images.map((image, index) => (
            <div className={`image-${index + 1}`} key={index}>
              <img onClick={() => handleShow(image.base64, image.seed)} src={`data:image/png;base64,${image.base64}`} alt="生成画像" />
            </div>
          ))}
        </div>
      </div>
      <div>
        <Modal
          centered
          show={show}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          title="選択した画像"
          body={
            <div className='show-image'>
              <img src={`data:image/png;base64,${props.selectedImage}`} alt="生成画像"></img>
            </div>
          }
          footer={
            <>
            <Button type="button" className="btn-3" variant="secondary" onClick={() => props.updateIsEdit()}>編集</Button>
            <Button type="button" className="btn-3" variant="secondary" onClick={handlePrintAndHide}>印刷</Button>
            </>
          }
          onHide={handleHide}>
        </Modal>
      </div>
    </div>
    <div className='button-container'>
    <Link to={`/Generateidea1?dowa=${props.fairyTale}`}><button className="return-btn" onClick={returnChooseScene} as="a" variant="primary">場面を選びなおす</button></Link>
      <Link to="/"><button variant="pink" className="btn-2">完了</button></Link>
    </div>
  </>
  );
}

export default GenerationResults_idea1;
