/*
引数：
base64Image ＝＞ `data:image/png;base64,${image.base64}`

モーダルで埋め込んでいる画像データをそのまま渡すと、
ブラウザの印刷機能が呼び出されます
iframeで子要素を埋め込み
*/
export const printImage = (base64Image) => {
    const iframe = document.createElement('iframe');
    iframe.style.visibility = 'hidden';
    iframe.style.position = 'absolute';
    iframe.style.left = '-9999px';
    document.body.appendChild(iframe);

    iframe.onload = function() {
        iframe.contentWindow.document.write(`<img src="${base64Image}" onload="window.print();">`);
        iframe.contentWindow.document.close();
    };

    iframe.src = 'about:blank';
};

/*
onClick={PrintImage}で使用できます。
印刷ボタンに設定するだけです。
*/