import { Card } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import "../src/GenerationResults_idea2_free.css";
import { Link } from 'react-router-dom';
import Modal from './components/Modal';
import { printImage } from './services/PrintImage';
import { initLocalStorage } from './services/apiConnect';
import translateText from './services/translateText';


function GenerationResults_idea2_free( { props }) {


  const [show, setShow] = useState(false);
  const [translatedPrompt, setTranslatedPrompt] = useState('')

  useEffect(() => {
    async function translate() {
      const translatedText = await translateText(props.generatePrompt);

      let elements = translatedText.split('、');
      if (elements.length > 9) {
        elements = elements.slice(0, 10);
      }
      const trimmedTranslatedText = elements.join('、');
      const formattedText = trimmedTranslatedText.replace(/、/g, '、 \n');
      setTranslatedPrompt(formattedText);
    }

    translate();
  }, [props.generatePrompt]);
  const handleShow = (base64, seed) => {
    setShow(true);
    props.updateSelectedImage(base64);
    props.updateSelectedImageSeed(seed);
    console.log(seed);
  }

  const handlePrintAndHide = () => {
    console.log('run handlePrintAndHide');
    printImage(`data:image/png;base64,${props.selectedImage}`);
    handleHide();
  };

  const handleHide = () => {
    setShow(false);
  }

  return (
    <div>
    
      <div className="GeneratedResult_idea2_free">

        <div className='GeneratedResult_idea2_free_main'>
          <div className='free_idea2_Image'>
            <div class="free_Result_idea2_text" >
              <p>
              <strong>入力した命令文</strong><br />
              {props.inputValue || "No subject"}<br />
              </p>
            </div>
          
            <Card className="Image-Card">
              <Card.Body>
                <Card.Text>
                  <div className='ImageSet1'>
                    <div className="Image1">
                    { props.images.map((image, index) => (
                      <div className={`Image-${index + 1}`} key={index}>
                        <img onClick={() => handleShow(image.base64, image.seed)} src={`data:image/png;base64,${image.base64}`} alt="生成画像" />
                      </div>
                    ))}
                  </div>
                </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className='.modal-5-content'>
        <Modal 
          centered
          show={show}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          title="選択した画像"
          body={
            <div className='show-image'>
              <img src={`data:image/png;base64,${props.selectedImage}`} alt="生成画像"></img>
            </div>
          }
          footer={
            <>
           <button type="button" className="btn-3" variant="secondary" onClick={() => props.updateIsEdit()}>編集</button>
            <Link to="/GenerationResults_idea2"><button type="button" className="btn-3" variant="secondary" onClick={handlePrintAndHide}>印刷</button></Link>
            </>
          }
          onHide={handleHide}>
        </Modal>
      </div>

          <div className='free-Image-Button'>
            <Card className="free-card-tips-main">
            <Card.Body>
              <Card.Title><h2>実際に生成された命令文</h2></Card.Title>
              <br />
              <Card.Text>
                <div style={{whiteSpace: 'pre-wrap', lineHeight: '1.7'}}>{translatedPrompt}</div>
              </Card.Text>
            </Card.Body>
            </Card>

            <div className="button-container">
             <Link to="/ImageSelection"><button className="return-button" onClick={initLocalStorage} as="a" variant="primary">お題を選びなおす</button></Link>
             <Link to="/"><button className="free-result-idea2-button" onClick={initLocalStorage} as="a" variant="primary" >完了</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerationResults_idea2_free;
