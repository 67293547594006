import React, {useEffect, useState, useMemo} from "react";
import { Tab,Tabs,Button } from 'react-bootstrap';

import "./Gallary.css";
import CommonHeader from './CommonHeader';
import { Link  } from "react-router-dom";
import Modal from './components/Modal';

import { BlobServiceClient } from '@azure/storage-blob';

function Gallary() {
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fairytaleImages, setFairytaleImages] = useState([]);
  const [themeImages, setThemeImages] = useState([]);
  const [activateTab, setActivateTab] = useState('fairytale-images');

  const [refreshKey, setRefreshKey] = useState(0);
  
  function onChangeTab(k) {
    console.log('k: ', k);
    setActivateTab(k);
  }
  useEffect(() => {
    let isMounted = true;

    const fetchImages = async (containerName, setImageState) => {
    //Storage Accountの認証
    const account = process.env.REACT_APP_STORAGE_ACCOUNT_NAME
    const sasToken = process.env.REACT_APP_STORAGE_SAS_TOKEN
    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net/?${sasToken}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);

      // コンテナ内の全blobをリストアップ
      let blobsList = [];

      // 画像を削除する処理
      // const cutoffDate = new Date('2023-11-30T13:00:00');
      // for await (const blob of containerClient.listBlobsFlat()) {
      //   if (blob.properties.lastModified < cutoffDate) {
      //     const blobClient = containerClient.getBlobClient(blob.name);
      //     await blobClient.delete();
      //     console.log(`Deleted blob: ${blob.name}`);
      //   }
      // }
      let blobCount = 0;

      for await (const blob of containerClient.listBlobsFlat()) {
        if (!isMounted) {
          return;
        }
        blobCount++;
        // push時に名前, 最終更新日, blobUrlをそれぞれマッピングしてListへ保存
        blobsList.push({ 
          name: blob.name, 
          // lastModified: blob.properties.lastModified,
          url: containerClient.getBlobClient(blob.name).url
        });
      }
      console.log("画像総枚数: ",containerName,blobCount);

      // 最新のBlob順にソート
      // blobsList.sort((a, b) => b.lastModified - a.lastModified);
       blobsList.reverse();

      //　maxResultsの数だけ最新情報として再リスト化
      const maxResults = 200;
      const latestBlobsList = blobsList.slice(0, maxResults);
      
      // ImageStateに保存
      if (isMounted) {
        setImageState(latestBlobsList.map(blob => blob.url));
      }
    };

    const fairytaleContainer = process.env.REACT_APP_STORAGE_CONTAINER_FAIRYTALE;
    const themeContainer = process.env.REACT_APP_STORAGE_CONTAINER_THEME;

    // 初回ロード時にそれぞれのコンテナ内の画像を読み込む処理
    fetchImages(fairytaleContainer, setFairytaleImages);
    fetchImages(themeContainer, setThemeImages);

    return () => {
      isMounted = false;
    };
  }, [refreshKey]);

  // tabによって表示する画像を切り替える
  const displayImages = useMemo(() => {
    return activateTab === 'fairytale-images' ? fairytaleImages : themeImages;
  }, [activateTab, fairytaleImages, themeImages]);

  const handleShow = (url) => {
    setShow(true);
    setSelectedImage(url);
  }

  const handleHide = () => { 
    setShow(false); 
    setSelectedImage(null);
  }

    return (
      <div className="Gallary">

        <CommonHeader /> 
          <div class="returnhome" >
            <Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
            </svg></Link>

            <Button type="button"  variant="warning" className="Gallary-button" onClick={() => setRefreshKey(old => old + 1)}>最新に更新</Button>
            
          </div>
        <div className="Gallary-main">
          <div className="Gallary-tab">
            <Tabs activeKey={activateTab} onSelect={onChangeTab} id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="fairytale-images" title="童話生成">
              <div className="Gallary-Image1">
                <div className="Gallary-Image2">
                  <div className="GallaryTab">
                    {displayImages.map((url, index) => (
                      <div key={index}>
                        <img onClick={() => handleShow(url)} src={url} loading="lazy" alt={`image_${index}`}/>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              </Tab>
              <Tab eventKey="theme-images" title="お題生成">
              <div className="Gallary-Image1">
                <div className="Gallary-Image2">

                  <div className="GallaryTab">
                    {displayImages.map((url, index) => (
                      <div key={index}>
                        <img onClick={() => handleShow(url)} src={url} loading="lazy" alt={`image_${index}`}/>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              </Tab>
            </Tabs>
            </div>
          </div>
          <div className='.modal-5-content'>
            <Modal 
              show={show}
              title="選択した画像"
              centered
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
              body={
                <div className='show-image-Gallary'>
                  <img src={selectedImage} alt="生成画像"></img>
                </div>
              }
              onHide={handleHide}>
            </Modal>
          </div>
      </div>
    );
  }
  
  export default Gallary;

