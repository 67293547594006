import React from 'react';
import CommonHeader from './CommonHeader';
import "../src/AppDescription.css";
import { Link } from 'react-router-dom';

//アプリの説明画面
function AppDescription() {
    return (
    <div>
      <CommonHeader />   
      <div className='text-itme-desc'>
      <h2>童話の場面生成についての説明</h2>
      <div className='text-explanation'>
      <p>
        8つの異なる童話の選択肢の中から1つ童話を選択し、そのあと場面を選択します。<br />
        童話と場面の組み合わせに基づく画像を生成します。<br />
        これにより、画像生成AIの独創的な童話の世界を楽しむことができます。<br />
        編集することも可能で、画風を変更することができます。
      </p>
      </div>
      <div className='text-h2'>
        <h2>注意事項</h2>
        <div className='text-explanation'>
          <p>
            ・画面のリロードをしないでください。<br />
            ・完了ボタンを押した後に、印刷をすることはできません。<br />
            ・必ずしも命令文通りに画像が生成されるとは限りません。<br />
          </p>
        </div>
      </div>
    </div>
      <Link to="/TaleSelection"><button type="button" className="btn1">確認</button></Link>
    </div>
  )
}

export default AppDescription;
