import React, { useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
import "./Generation_idea2_free.css"
import CommonHeader from './CommonHeader';
import { Link } from "react-router-dom";
import Loading from './components/Loading';
import { fetchThemeData } from './services/apiConnect';
import { saveToHistory } from './services/apiConnect';
import { initLocalStorage } from "./services/apiConnect";
import GenerationResults_idea2_free from './GenerationResults_idea2_free';
import ImageEdit from './ImageEdit';


function Generation_idea2_free() {

  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState(null);
  const [seed, setSeed] = useState(0);
  const [images, setImages] = useState([]);
  const [generatePrompt, setGeneratePrompt] = useState('');
  const [isGenerate, setIsGenerate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [textValue, setTextValue] = useState("");  // テキストエリアの値を保持
  const [selectedOption, setSelectedOption] = useState("");  // ラジオボタンの選択を保持
  const [randomExampleTexts, setRandomExampleTexts] = useState([]);

  const exampleTexts = [
    '熊がバスケをしている',
    '空を飛ぶ車に乗るウサギ',
    'パフェを食べるツインテールの女の子',
    '断崖絶壁の上に立っている古びたお城、月明かりに照らされている',
    '宇宙が背景の公園',
    '犬がボールをくわえて走っている',
    '氷のお城とオーロラ'
  ]

  useEffect(() => {
    // 
    const shuffled = exampleTexts.sort(() => 0.5 - Math.random());
    setRandomExampleTexts(shuffled.slice(0, 3));
  }, [])

  const handleGenerate = async (seed) => {
    console.log(inputValue);
    if (inputValue !== '') {
      try {
        setIsLoading(true);
        const user_input = inputValue;
        const data = await fetchThemeData(user_input, seed); 
        setImages(data.generate_images.map(item => (
          {
            "base64": item.base64,
            "seed": item.seed
          }
        ))); // 画像データをマッピングして保持
        setGeneratePrompt(data.generate_prompt);
        // console.log("===save To History called===")
        saveToHistory(user_input, data.generate_prompt); // historyに保存
        setTimeout(() => {
          setIsLoading(false);
          setIsGenerate(true);
        }, 500);  
        // ロード中にエラーが発生した時の処理
      } catch (error) {
        console.error('APIリクエストエラー: ', error);
        alert('画像が生成できませんでした。もう一度命令を変えて試してください。');

        // リクエスト時に入力した値を戻す
        setInputValue(inputValue);
        setIsLoading(false);
        console.log("setInputValue: ", inputValue);
      }
    } else {
      alert('命令は必ず入力してください');
    }
  }

  const updateIsEdit = () => {
    setIsEdit(true);
  }

  const updateSelectedImage = (base64) => {
    setSelectedImage(base64);
  }
  const updateSelectedImageSeed = (seed) => {
    setSeed(seed);
  }

  const handleInputChange = (e) => {
    console.log(e.target.value);
    setInputValue(e.target.value);
  }

  const handleExampleTextClick = (text) => {
    setInputValue(text);
  }

  if (isLoading) {
    return <Common><Loading /></Common>;
  }

  // 生成結果画面
  if (!isLoading && isGenerate && !isEdit) {
    return <Common><GenerationResults_idea2_free props={{images, setImages, generatePrompt, handleGenerate, updateIsEdit, selectedImage, updateSelectedImage, updateSelectedImageSeed, inputValue}} /></Common>
  }

  // 編集画面
  if (!isLoading && isGenerate && isEdit) {
    return <Common><ImageEdit props={{setIsLoading, setIsGenerate, selectedImage, seed, updateIsEdit, updateSelectedImage, setIsEdit, setTextValue, setSelectedOption, selectedOption, inputValue, textValue}} /></Common>
  }


  return (
  <Common>
    <div className="Generation_idea2_free">

      <div className="Generation_idea2_free_main">
        <div className="form1">
          <h2 className="free-text-start-main">命令参考例</h2>
          <div className="display-only form-control form-control-lg">
          <p>
            {randomExampleTexts.map((text, index) => (
              <React.Fragment key={index}>
                {index > 0 && <br/>} {/* 最初の要素以外の前に改行を挿入 */}
                <span onClick={() => handleExampleTextClick(text)}>
                ・ {text}
                </span>
              </React.Fragment>
            ))}
          </p>
          </div>
          <h2 className="free-text-start-main">命令を入力する</h2>
          <h5 style={ {color: 'red'} }>※必須</h5>
          <textarea className="free-text form-control form-control-lg" type="text" placeholder="例：熊が森でオレンジジュースを飲む" value={inputValue} aria-label=".form-control-lg example" onChange ={handleInputChange}></textarea>
          
          <div className="free-Note"> 
            <div className="free-Note-text">
              <h2>注意事項</h2>
                <p className="free-text-start-Note">
                  ・特定の日本のもの（例：こたつ）は生成が難しいです。<br />
                  ・著作権侵害にあたるものは生成しないでください。<br />
                   （ディズニー、ポケモンなどのアニメキャラ、有名人など）<br />
                  ・再生成でうまく反映されなかった場合、同じ命令文で何度か再生成を<br />
                  　していただくと、反映されることがあります。<br />
                  ・再生成で色の指定をする場合、反映されない場合があります。<br />
                  ・再生成で画像内のものを削除するか色の変更をする場合、命令文に<br />
                  　入れて作成された要素の削除/変更は可能ですが、AIによって自動で<br />
                  　作成された要素の削除/変更は難しい場合があります。<br />
                  ・複数のものが生成された場合、再生成での色の指定や削除、<br />
                  　変更が難しい場合があります。
                </p>
            </div>
          </div> 
        </div>

        <div className="Creation-tips">
          {/* 画像の表示 */}
          <Card className="card-tips-main">
            <Card.Body>
              <Card.Title><h2><b>作成のポイント</b></h2></Card.Title>
              <Card.Text>
                ・AIに何を書いてほしいか、ハッキリ伝えましょう。<br />
                　「<b>誰</b>が<b>何</b>をしている」という様に入れると◎<br /> 
                　　(例：犬が公園で遊んでいる絵)<br />
                ・空想の風景や<ruby>幻想的<rt>げんそうてき</rt>な世界を作ることができます！</ruby><br />
                　　(例：夜空の下に森が広がっている, 氷のお城とオーロラ) <br />
                ・背景に何があるかを伝えるのもいいです。<br />
                　　(例：宇宙が背景の公園)<br />
                <br />
                <u><b>色々な命令を試して、どんな絵ができるか楽しみましょう！</b></u>
              </Card.Text>
            </Card.Body>
          </Card>

          <div className="free-idea2-Button">
          <Link to="/ImageSelection"><button className="free-idea2-Button1" onclick={initLocalStorage} as="a" variant="primary" >お題を選びなおす</button></Link>
          <button className="free-idea2-Button2" as="a" variant="primary" onClick={() => handleGenerate(seed)}>生成</button>
          </div>
        </div>

      </div>
    </div>
  </Common>
  );
}

function Common({ children }) {
  return (
    <div className='imageselect'>
      <CommonHeader />
      {children}
    </div>
  )
}

export default Generation_idea2_free;
