const translateText = async (text, targetLang = 'ja') => {
    const subscriptionKey = process.env.REACT_APP_TEXT_TRANSLATE_KEY;
    const endpoint = `${process.env.REACT_APP_TEXT_TRANSLATE_ENDPOINT}translate?api-version=3.0&to=${targetLang}`;
    const location = 'japaneast';
    const body = [{ 'Text': text }];

    try {
        const response = await fetch (endpoint, {
            method: 'POST',
            headers: {
                'Ocp-Apim-Subscription-Key': subscriptionKey,
                'Ocp-Apim-Subscription-Region': location,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body),
        });
        if (!response.ok) {
            const errorBody = await response.text();
            console.error('Response error body: ', errorBody);
            throw new Error(`Error: ${response.status}`);
        }
        
        const data = await response.json();
        return data[0].translations[0].text;
    } catch (error) {
        console.error('Translation error:', error);
        return '翻訳に失敗しました';
    }
};

export default translateText;