import React from 'react';
import CommonHeader from './CommonHeader';
import douwa3 from "../src/images/image3.jpg";
import douwa4 from "../src/images/image4.jpg";
import douwa5 from "../src/images/image5.jpg";
import douwa6 from "../src/images/image6.jpg";
import douwa7 from "../src/images/image7.jpg";
import douwa8 from "../src/images/image8.jpg";
import douwa9 from "../src/images/image9.jpg";
import douwa10 from "../src/images/image10.jpg";

import { Link } from 'react-router-dom';
import "../src/TaleSelection.css"

//童話選択画面
function TaleSelection() {
  return (
    <div className="parentContainer">
      <CommonHeader />
      <p className='select-douwa'>童話の選択</p>
      {/* 画像生成のコンテンツを追加 */}
      <div className='waku'>
      <label>
          <Link to="/Generateidea1?dowa=シンデレラ"><img src={douwa3} alt="シンデレラ"className='selectable-image' ></img></Link>
          <p>シンデレラ</p>
      </label>
      <label>
          <Link to="/Generateidea1?dowa=美女と野獣"><img src={douwa4} alt="美女と野獣"className='selectable-image' ></img></Link>
          <p>美女と野獣</p>
      </label>
      <label>
          <Link to="/Generateidea1?dowa=ヘンゼルとグレーテル"><img src={douwa5} alt="ヘンゼルとグレーテル"className='selectable-image' ></img></Link>
          <p>ヘンゼルとグレーテル</p>
      </label>
      <label>
          <Link to="/Generateidea1?dowa=不思議な国のアリス"><img src={douwa6} alt="不思議な国のアリス"className='selectable-image' ></img></Link>
          <p>不思議な国のアリス</p>
      </label>
      <label>
          <Link to="/Generateidea1?dowa=白雪姫"><img src={douwa7} alt="白雪姫"className='selectable-image' ></img></Link>
          <p>白雪姫</p>
      </label>
      <label>
          <Link to="/Generateidea1?dowa=ラプンツェル"><img src={douwa9} alt="ラプンツェル"className='selectable-image' ></img></Link>
          <p>ラプンツェル</p>
      </label>
      <label>
          <Link to="/Generateidea1?dowa=ジャックと豆の木"><img src={douwa8} alt="ジャックと豆の木"className='selectable-image' ></img></Link>
          <p>ジャックと豆の木</p>
      </label>
      <label>
          <Link to="/Generateidea1?dowa=ピーターパン"><img src={douwa10} alt="ピーターパン"className='selectable-image' ></img></Link>
          <p>ピーターパン</p>
      </label>
      </div>
    </div>
  );
}

export default TaleSelection;

