import React, { Component, useState,  } from "react";
import { Card,Image } from 'react-bootstrap';
import Theme01 from "../src/images/theme01.png";
import Theme02 from "../src/images/theme02.png";
import Theme03 from "../src/images/theme03.png";
import Theme04 from "../src/images/theme04.png";
import Theme05 from "../src/images/theme05.png";
import Theme06 from "../src/images/theme06.png";
import Theme07 from "../src/images/theme07.png";
import "./Generation_idea2.css"
import CommonHeader from './CommonHeader';
import { Link, useLocation } from "react-router-dom";
import imagejson from './data/imageselect.json'
import Loading from './components/Loading';
import GenerationResults_idea2 from './GenerationResults_idea2';
import { fetchThemeData } from './services/apiConnect';
import { saveToHistory } from './services/apiConnect';
import { initLocalStorage } from "./services/apiConnect";
import ImageEdit from './ImageEdit';

function Generation_idea2 () {

  const [inputValues, setInputValues] = useState({
    Subject:'',
    Action:'',
    Location:'',
    AdditionalDetails:'',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState(null);
  const [seed, setSeed] = useState(0);
  const [images, setImages] = useState([]);
  const [isGenerate, setIsGenerate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [textValue, setTextValue] = useState("");  // テキストエリアの値を保持
  const [selectedOption, setSelectedOption] = useState("");  // ラジオボタンの選択を保持
  const location = useLocation();
  const queryPrams = new URLSearchParams(location.search);
  const params = queryPrams.get('dowa');
  let data = [];
  let tipsdatas = [];

  switch(params) {
    case "Theme1":
      data = Theme01
      tipsdatas = imagejson.Theme1
      break;
    case "Theme2":
      data = Theme02
      tipsdatas = imagejson.Theme2
      break;
    case "Theme3":
      data = Theme03
      tipsdatas = imagejson.Theme3
      break;
    case "Theme4":
      data = Theme04
      tipsdatas = imagejson.Theme4
      break;
    case "Theme5":
      data = Theme05
      tipsdatas = imagejson.Theme5
      break;
    case "Theme6":
      data = Theme06
      tipsdatas = imagejson.Theme6
      break;
    default:
      data = Theme07
      tipsdatas = imagejson.Theme7
      break;
    }

  
    const handleGenerate = async (seed) => {
      console.log(inputValues);
      if (
          (inputValues['Subject'].trim() !== '' && inputValues['Action'].trim() !== '' && inputValues['Location'].trim() !== '')
      ) {
        setIsLoading(true);
        try {
          const user_input = Object.entries(inputValues).map(([key, value]) => `${key}: '${value}'`).join(', ');
          // const user_input = Object.values(inputValues).join('');
          console.log("userInput: ", user_input);
          const data = await fetchThemeData(user_input, seed); 
          setImages(data.generate_images.map(item => (
            {
              "base64": item.base64,
              "seed": item.seed
            }
          ))); // 画像データをマッピングして保持
          // console.log("===save To History called===")
          saveToHistory(user_input, data.generate_prompt); // historyに保存
          setTimeout(() => {
            setIsLoading(false);
            setIsGenerate(true);
          }, 500);  
        // ロード中にエラーが発生した時の処理
        } catch (error) {
          console.error('APIリクエストエラー: ', error);
          alert('画像が生成できませんでした。もう一度命令を変えて試してください。');

          // リクエスト時に入力した値を戻す
          setInputValues({
              Subject:inputValues['Subject'],
              Action:inputValues['Action'],
              Location:inputValues['Location'],
              AdditionalDetails:inputValues['AdditionalDetails']
            });
          setIsLoading(false);
          console.log(inputValues);
        }
      } else {
        alert('その他以外の項目は必ず入力してください');
      }
    }
  
    const updateIsEdit = () => {
      setIsEdit(true);
    }
  
    const updateSelectedImage = (base64) => {
      setSelectedImage(base64);
    }
    const updateSelectedImageSeed = (seed)=> {
      setSeed(seed);
    }
  

    const handleInputChange = (name, value) => {
      console.log(name, value);
      setInputValues({ ...inputValues,[name]: value })
    }

    if (isLoading) {
      return <Common><Loading /></Common>;
    }
  
    if (!isLoading && isGenerate && !isEdit) {
      return <Common><GenerationResults_idea2 props={{images, setImages, handleGenerate, updateIsEdit, selectedImage, updateSelectedImage, updateSelectedImageSeed, inputValues}} /></Common>
    }

    if (!isLoading && isGenerate && isEdit) {
      return <Common><ImageEdit props={{setIsLoading, setIsGenerate, selectedImage, seed, updateIsEdit, updateSelectedImage, setIsEdit, setTextValue, setSelectedOption, selectedOption, textValue}} /></Common>
    }

  return (
    <Common>
    <div className="Generation_idea2">

      <div className="Generation_idea2_main">
        <div className="form1">
          {/* <form className="textx-Box" onSubmit={handleSubmit}> */}
          <form className="textx-Box">
             <h2 className="text-start-main">主題（人、建物、動物、ものなど）</h2>
             <h5 style={ {color: 'red'} }>※必須</h5>
            <input className="form-control form-control-lg" type="text" placeholder="テキスト" value={inputValues.Subject} aria-label=".form-control-lg example" onChange ={(e) => handleInputChange('Subject', e.target.value)} required></input>

            <h2 className="text-start-main">様子（動き、状態など）</h2>
            <h5 style={ {color: 'red'} }>※必須</h5>
            <input className="form-control form-control-lg" type="text" placeholder="テキスト" value={inputValues.Action} aria-label=".form-control-lg example" onChange ={(e) => handleInputChange('Action', e.target.value)} required></input>

            <h2 className="text-start-main">場所（森、町、海など）</h2>
            <h5 style={ {color: 'red'} }>※必須</h5>
            <input className="form-control form-control-lg" type="text" placeholder="テキスト" value={inputValues.Location} aria-label=".form-control-lg example" onChange ={(e) => handleInputChange('Location', e.target.value)} required></input>

            <h2 className="text-start-main">その他（表情、服装、髪型など）</h2>
            <input className="form-control form-control-lg" type="text" placeholder="テキスト" value={inputValues.AdditionalDetails} aria-label=".form-control-lg example" onChange ={(e) => handleInputChange('AdditionalDetails', e.target.value)}></input>
          </form>
        <div className="Note"> 
          <div className="Note-text">
            <h2>注意事項</h2>
              <p class="text-start-Note">
              ・特定の日本のもの（例：こたつ）は生成が難しいです。<br />
              ・著作権侵害にあたるものは生成しないでください。<br />
              （ディズニー、ポケモンなどのアニメキャラ、有名人など）<br />
              ・再生成でうまく反映されなかった場合、同じ命令文で何度か再生成を<br />
              　していただくと、反映されることがあります。<br />
              ・再生成で色の指定をする場合、反映されない場合があります。<br />
              ・再生成で画像内のものを削除するか色の変更をする場合、命令文に<br />
              　入れて作成された要素の削除/変更は可能ですが、AIによって自動で<br />
              　作成された要素の削除/変更は難しい場合があります。<br />
              ・複数のものが生成された場合、再生成での色の指定や削除、<br />
              　変更が難しい場合があります。
              </p>
          </div>
        </div> 
        </div>

        <div className="generated-image-main">
          <Card className="generated-image">
            <Card.Body>
              <Card.Title>
              {tipsdatas.map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  {index !== tipsdatas.length - 1 && <br />} {/* 最後の要素以外に改行を追加 */}
                </React.Fragment>
              ))}
              </Card.Title>
              <Card.Text>
                <div className="imageselect">
                  <div className="Image01">
                    <Image src={data} />
                  </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          {/* <div className="Image01">
            <img src={Bear} />
          </div> */}
          <div className="idea2-Button">
            <Link to="/ImageSelection"><button className="idea2-button1" onclick={initLocalStorage} as="a" variant="primary" >お題を選びなおす</button></Link>
            <button variant="pink" type="button" className="idea2-button2" onClick={() => handleGenerate(seed)}>生成</button>
          </div>
        </div>
      </div>
    </div>
    </Common>
  );
}

function Common({ children }) {
  return (
    <div className='imageselect'>
      <CommonHeader />
      {children}
    </div>
  )
}

export default Generation_idea2;
