import 'bootstrap/dist/css/bootstrap.min.css';
import CommonHeader from './CommonHeader';
import Home from "./Home";

//ホームとHeaderを読み込む
function App() {
  return (
    <div>
      <CommonHeader />
      <Home />
    </div>
  )
}

export default App;
